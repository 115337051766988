import { useEffect, useRef, useState } from "react";
import { createContainer } from "unstated-next";
import { Login, Register, getCurrentSession, GetNewToken, UpdateLastLogin } from "../api/auth";
import { useRequest } from "../api/utils";
import { LoginParams, SignUpParams } from "../api/interfaces/auth.interface";
import { useRouter } from "next/dist/client/router";
import { delay, subdomainStoreLink, toAppUrl } from "../assets/js/utils/functions";
import { useModals } from "../components/hooks/useModals";
import supportedCountries from "../assets/js/utils/supported-countries.json";
import { KYCInfo, Referrals, StoreInterface, StoreRoles } from "../assets/interfaces";
import routeGuard from "./route-guard";
import routes from "../assets/js/utils/routes";
import { GetStoreKYC } from "../api/store.kyc";
import { Action, BrowserHistory, createBrowserHistory } from "history";
import { GetReferralRewards, GetStoreReferrals } from "@/api/credits-and-referrals";

function useAuthContext() {
  const router = useRouter();

  //controls
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const [fetchError, setFetchError] = useState<boolean>(false);
  const [pageIsReady, setPageIsReady] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");
  const settingUp = useRef(false);
  const [isSwitchingStore, setIsSwitchingStore] = useState(false);
  const [isCreatingStore, setIsCreatingStore] = useState(false);

  //state -> user, store, stores
  const [user, setUser] = useState<any>(getUser());
  const [storeId, setStoreId] = useState(null);
  const stores: StoreInterface[] = user?.stores;
  const store: StoreInterface = getStore();
  const storeIndex = store ? stores?.findIndex(({ id }) => id === store?.id) : 0;
  const [history, setHistory] = useState<string[]>([]);
  const [browerHistory, setBrowerHistory] = useState<BrowserHistory>(null);
  const [rewards, setRewards] = useState(null);
  const [referrals, setReferrals] = useState<Referrals>(null);

  //Requests
  const loginRequest = useRequest<LoginParams>(Login);
  const signupRequest = useRequest<SignUpParams>(Register);
  const getNewTokenRequest = useRequest(GetNewToken);
  const sessionRequest = useRequest(getCurrentSession);
  const updateLastLoginRequest = useRequest(UpdateLastLogin);
  const getReferralRewardsReq = useRequest(GetReferralRewards);
  const getReferralsReq = useRequest(GetStoreReferrals);

  //Miscalenous
  const { modals, toggleModal } = useModals(["renewal"]);

  useEffect(() => {
    // router.events.off("routeChangeComplete", (p) => redirectUser(p));
    redirectUser(router.asPath);

    if (history[history.length - 1] !== router.asPath) {
      setHistory([...history, router.asPath]);
    }
  }, [router.asPath]);

  //sets available data when app loads
  //makes a request to fetch updated user data
  useEffect(() => {
    setBrowerHistory(createBrowserHistory());

    let storeId;

    if (localStorage.token) {
      setIsAuthenticated(true);
      storeId = localStorage["current-store"];
      setStoreId(storeId ?? null);
      setUser(getUser());
      fetchUserSession();
      // getNewToken(storeId);

      const lastLogin = sessionStorage.getItem("last-login");

      if (!lastLogin) {
        updateLastLoginRequest.makeRequest({});
        sessionStorage.setItem("last-login", String(Date.now()));
      }
    }

    //Get referral rewards
    getReferralRewards();
    getReferrals();

    redirectUser(router.asPath, storeId);

    const hideContents = () => setPageIsReady(false);

    router.events.on("routeChangeStart", hideContents);

    return () => {
      router.events.off("routeChangeStart", hideContents);
    };
  }, []);

  //sets the current store's currency
  useEffect(() => {
    const { userPaths, authPaths } = routes;

    window.COUNTRY = store?.country;
    window.CURRENCY = store?.currencies?.products;
    window.CONVERSION_RATES = store?.currencies?.rates ?? {};
  }, [store, router.pathname]);

  //handles guarding routes
  const redirectUser = (currentPath: string, sId?: string) => {
    const user = getUser();
    const storeId = localStorage["current-store"];
    const storeIndex = storeId ? user?.stores?.findIndex(({ id }) => id === storeId) : 0;

    routeGuard({
      currentPath: router.pathname,
      router,
      user,
      setRedirectTo,
      setPageIsReady,
      settingUp,
      storeIndex: storeIndex < 0 ? 0 : storeIndex,
    });
  };

  async function getReferralRewards() {
    const [res, err] = await getReferralRewardsReq.makeRequest({});

    if (res) {
      setRewards(res?.data);
    }
  }
  const getReferrals = async () => {
    const [res, err] = await getReferralsReq.makeRequest({});

    if (res) {
      setReferrals(res?.data);
    }
  };

  async function fetchUserSession() {
    const [res, err] = await sessionRequest.makeRequest({});

    if (!err) {
      const loggedInUser = res;
      localStorage.user = JSON.stringify(loggedInUser);

      verifyCurrentStore(loggedInUser);
      setUser(loggedInUser);

      return true;
    } else {
      setFetchError(true);
    }

    return false;
  }

  async function login(data: LoginParams, redirect?: boolean) {
    const [response, error] = await loginRequest.makeRequest(data);
    if (error) return [response, error];

    return handleSuccess(response, redirect);
  }

  async function register(data: SignUpParams, redirect?: boolean) {
    const [response, error] = await signupRequest.makeRequest(data);
    if (error) return [response, error];

    return handleSuccess(response, redirect);
  }

  function handleSuccess(response: any, redirect: boolean = true) {
    const loggedInUser = response.user;
    localStorage.token = response.token;
    localStorage.user = JSON.stringify(loggedInUser);

    verifyCurrentStore(loggedInUser);

    setUser(loggedInUser);
    setIsAuthenticated(true);

    if (redirect) {
      handleRedirects();
    }

    return [response, null];
  }

  function verifyCurrentStore(loggedInUser: any) {
    const storedStoreIndex = localStorage["current-store"];
    const userHasStore = loggedInUser?.stores.findIndex((s) => s.id === storedStoreIndex) > -1;

    if (!storedStoreIndex || !userHasStore) {
      localStorage.setItem("current-store", loggedInUser?.stores[0]?.id);
      setStoreId(loggedInUser?.stores[0]?.id);
    }
  }

  function getUser() {
    try {
      const storedUser = { ...JSON.parse(localStorage.user) };

      //logout old users with stores as array strings
      if (typeof storedUser.stores[0] === "string") {
        logout();
      }

      if (isAuthenticated || localStorage.token) return storedUser;

      return defaultUser;
    } catch (e) {
      return defaultUser;
    }
  }

  function getStore() {
    if (!user || Object.keys(user).length < 1) {
      return null;
    }

    if (!storeId) return null;

    return user?.stores.find((s) => s.id === storeId);
  }

  function logout(redirect: boolean = true) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("current-store");
    setIsAuthenticated(false);

    if (redirect) router.push("/login");
  }

  function updateUser(newUserData: any) {
    const newUser = { ...user, ...newUserData };
    localStorage.setItem("user", JSON.stringify(newUser));
    setUser(getUser());
  }

  async function getNewToken(storeId?: string) {
    const [res, err] = await getNewTokenRequest.makeRequest({ store: storeId });
    if (res) {
      localStorage.token = res.data.token;
      localStorage["current-store"] = storeId;
      setStoreId(storeId);
    }
  }

  async function switchStore(store: string) {
    if (store) {
      setIsSwitchingStore(true);
      await getNewToken(store);
      await delay(1000);
      window.location.reload();
    }
  }

  function updateStore(data: Partial<StoreInterface>) {
    const storeIndex = stores?.findIndex((s) => s.id === store?.id);
    const storeData = { ...store, ...data };
    const userStores = [...user?.stores];
    userStores[storeIndex] = storeData;

    updateUser({ stores: userStores });
  }

  function handleRedirects() {
    if (redirectTo) {
      router.push(redirectTo);
      setRedirectTo("");
      return;
    }

    router.push("/dashboard");
  }

  function onRouteBack(callback: (path?: string) => void) {
    browerHistory.listen(({ action, location }) => {
      if (action === Action.Pop) callback(`${location.pathname}${location.search}`);
    });
  }

  return {
    isSwitchingStore,
    isCreatingStore,
    setIsCreatingStore,
    switchStore,
    storeIndex,
    user,
    isAuthenticated,
    login,
    loginRequest,
    signupRequest,
    register,
    logout,
    setIsAuthenticated,
    updateUser,
    handleSuccessfulLogin: handleSuccess,
    stores,
    store,
    storeId,
    setStoreId,
    fetchError,
    categories: store?.categories,
    pageNotReady: !isAuthenticated || !pageIsReady || !user || (user?.stores?.length > 0 && !store),
    storeLink: store ? subdomainStoreLink(store.slug, true) : "",
    getNewToken,
    subscription: store?.subscription ?? user?.subscription,
    appModals: modals,
    toggleAppModals: toggleModal,
    userRole: getUserRole(store, user),
    getRewards: () => {
      const sortedStores = stores.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      const country = sortedStores[0]?.country;

      return rewards ? { amount: rewards[country?.code ?? "NG"], currency: country?.currency || "NGN" } : null;
    },
    updateStore,
    redirectTo,
    handleRedirects,
    history,
    onRouteBack,
    referrals,
  };
}

const defaultCountry = {
  name: "Nigeria",
  currency: "NGN",
  code: "NG",
  dial_code: "+234",
  emoji: "🇳🇬",
};

export const getCountryFromCountries = (c: string) => {
  if (!c) return defaultCountry;

  return supportedCountries.find((cx) => cx.code === c);
};

export const getUserRole = (store: StoreInterface, user: any) => {
  if (store && user && store?.owner === user.id) {
    return StoreRoles.OWNER;
  }
  return store?.owners?.find((owner) => owner.user === user.id)?.role ?? StoreRoles.OPERATOR;
};

const defaultUser = {
  id: "",
  name: "",
  email: "",
  stores: [],
  email_verified: false,
  phone_verified: false,
  plan: "",
  phone: "",
  avatar: "",
  createdAt: null,
  updatedAt: null,
  isAuthenticated: false,
  subscription: null,
};

let authContext = createContainer(useAuthContext);

export default authContext;
